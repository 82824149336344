<template>
   <div class="content">
        <div class="contact">
            <div class="h6">{{$t('message.ContactUs')}}</div>
            <div class="list flexJA flexSb">
                <div class="item flexJA flexFs" @click="LiveChatMax()">
                    <img src="@/assets/images/10.png" alt="">
                    <div class="text">{{$t('message.OnlineConsultant')}}</div>
                </div>
                <div class="item flexJA flexFs">
                    <img src="@/assets/images/11.png" alt="">
                    <div class="text">{{homeBase.email}}</div>
                </div>
            </div>
        </div>
        <div class="collapse">
            <van-collapse v-model="activeName" accordion>
                <van-collapse-item v-for="(item,index) in bottomNav" :title="item.name" :name="index" :key="index">
                    <template #icon>
                      <img v-if="item.id == 1" src="@/assets/images/12.png" alt="">
                      <img v-if="item.id == 35" src="@/assets/images/13.png" alt="">
                      <img v-if="item.id == 42" src="@/assets/images/14.png" alt="">
                      <img v-if="item.id == 47" src="@/assets/images/15.png" alt="">
                    </template>
                    <div class="list">
                        <div class="item" v-for="items in item.sub" @click="bindNav(item.id, items.id)">{{items.name}}</div>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
        <div class="title">{{$t('message.WEACCEPT')}}</div>
        <div class="brand flexJA flexSb">
            <div class="item flexJA" v-for="item in homeBase.nine_pictures"><img class="icon" :src="item.image" alt="" /></div>
     
        </div>
        <div class="tips flexJA">{{homeBase.copy_right}}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'BottomMenu',
    props: {

    },
    data() {
        return {
            activeName: '',
        }
    },
    computed: {
        ...mapState(['homeBase','bottomNav'])
    },
    methods: {
        LiveChatMax() {
            // 最大化了聊天小部件
            LiveChatWidget.call("maximize");
        },
        bindNav(oid, tid) {
            let url = `/articleList?oid=${oid}&tid=${tid}`
            this.$router.push(url)
            this.activeName = ''
        },
    }
}
</script>

<style scoped lang="scss">
    .content {
        // background: #d8eaf4;
        background: #F4E5E5;
    }
    .contact {
        padding: 15px;
        .h6 {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 5px;
        }
        .list {
            .item {
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 15px;
                }
                .text {
                    font-size: 12px;
                    font-weight: bold;
                    color: #333333;
                }
            }
        }
    }
    .collapse {
        img {
            width: 24px;
            height: 24px;
            margin-right: 15px;
        }
        .list {
            margin-left: 55px;
            .item {
                font-size: 14px;
                color: #666666;
                padding: 5px 0;
            }
        }
    }
    .title {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        margin-left: 15px;
        margin-top: 15px;
    }
    .brand {
        padding: 15px 15px 0;
        flex-wrap: wrap;
        .item {
            width: 100px;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;
            margin-bottom: 10px;
            .icon {
                width: 38px;
                height: 34px;
            }
            .icon2 {
                width: 50px;
                height: 30px;
            }
            .icon3 {
                width: 38px;
                height: 31px;
            }
            .icon4 {
                width: 30px;
                height: 30px;
            }
            .icon5 {
                width: 44px;
                height: 28px;
            }
            .icon6 {
                width: 40px;
                height: 26px;
            }
        }
    }
    .tips {
        font-size: 10px;
        color: #666666;
        padding: 15px;
    }

    ::v-deep .van-cell {
        padding: 15px;
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        // background: #d8eaf4;
        background: #F4E5E5;
        border-bottom: 1px solid #E7F2F9;
    }
    ::v-deep .van-collapse-item__content {
        padding: 5px 0;
    }
</style>
