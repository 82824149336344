<template>
    <div class="content">
        <!-- <div class="nav flexJA flexFs">
            <div class="unfold" @click="showPopup()"><img class="wh100" src="@/assets/images/1.png" alt=""></div>
            <router-link to="/" class="logo"><img src="@/assets/images/2.png" alt=""></router-link>
            <div class="menu flexJA flexFs">
                <router-link to="/search"><img src="@/assets/images/3.png" alt=""></router-link>
                <router-link to="/wishList"><img src="@/assets/images/4.png" alt=""></router-link>
                <router-link to="/design"><img src="@/assets/images/5.png" alt=""></router-link>
                <router-link to="/cart"><img src="@/assets/images/6.png" alt=""></router-link>
            </div>
        </div> -->
        <div class="top">
            <div class="black flexJA">{{$t('message.HomeFD')}}</div>
            <div class="menu flexJA flexSb">
                <div class="left flexJA flexFs">
                    <img class="unfold" @click="showPopup()" src="@/assets/images/103.png" alt="">
                    <router-link to="/"><van-image class="logo" :src="homeBase.web_logo" fit="cover" lazy-load /></router-link>
                </div>
                <div class="right flexJA flexFs">
                    <router-link to="/storeService?type=2"><img class="icon" src="@/assets/images/116.png" alt=""></router-link>
                    <router-link to="/wishList"><img class="icon" src="@/assets/images/4.png" alt=""></router-link>
                    <router-link to="/design"><img class="icon" src="@/assets/images/5.png" alt=""></router-link>
                    <router-link to="/cart"><img class="icon" src="@/assets/images/6.png" alt=""></router-link>
                </div>
            </div>
        </div>
        <!-- 侧边弹出窗 -->
        <van-popup class="popup" v-model="show" position="left">
            <!-- <router-link to="/set"class="top flexJA flexFs">
                <img src="@/assets/images/7.png" alt="">
                <div class="text">{{config.countries}} {{config.language}} / {{config.currency}}</div>
                <van-icon name="arrow" color="#666666" size="24px" />
            </router-link> -->
            <div class="above flexJA flexFs" @click="showSet = true, show = false">
                <img src="@/assets/images/7.png" alt="">
                <div class="text">{{config.countries}} {{config.language}} / {{config.currency}}</div>
                <van-icon name="arrow" color="#666666" size="24px" />
            </div>
            <div class="info flexJA flexFs">
                <div class="flexJA flexFs" v-if="token && userInfo" @click="jumpTo('/myCenter')">
                    <div class="circle flexJA uppercase">{{userInfo.lastname.slice(0,1)}}</div>
                    <div class="name">{{$t('message.Hi')}},{{userInfo.firstname}} {{userInfo.lastname}}</div>
                </div>
                <div class="flexJA flexFs" v-if="!token">
                    <div class="circle flexJA"><van-icon name="contact" /></div>
                    <div class="name">
                        <span @click="jumpTo('/login')">{{$t('message.LOG_IN')}}</span>
                        <span class="line">/</span>
                        <span class="uppercase" @click="jumpTo('/register')">{{$t('message.signUp')}}</span>
                    </div>
                </div>
            </div>                
            <div class="collapse">
                <van-collapse v-model="activeName" accordion>
                    <van-collapse-item :title="item.name" :name="index + 1" :is-link="false" v-for="(item, index) in topNav" :key="index">
                        <div v-for="item2 in item.child">
                            <div class="title" @click="bindCommodityList(item2)">{{item2.name}}</div>
                            <div class="list">
                                <div class="text" v-for="item3 in item2.child" @click="bindCommodityList(item3)">{{item3.name}}</div>
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </van-popup>
        <!-- 设置 -->
        <van-popup class="set" v-model="showSet" position="bottom" :style="{ height: '100%' }" duration="0">
            <div class="top flexJA">
                <div @click="showSet = false"><img src="@/assets/images/21.png" alt=""></div>
                <div class="title">{{$t('message.set_title')}}</div>
            </div>
            <div class="pt46">
                <div class="tips">{{$t('message.set_text')}}</div>
                <div class="list">
                    <div class="item">
                        <div class="text">{{$t('message.ShippingTo')}}</div>
                        <!-- <div class="bar flexJA flexFs">
                            <div class="tag flexJA" :class="{active: lan.countriesCode == item.country_symbol}" v-for="item in countryList" @click="switchCountry(item.country_symbol)">{{item.country_code}}</div>
                        </div> -->
                        <div class="input flexJA">
                            <input type="text" :placeholder="$t('message.pleases')" disabled v-model="countryName">
                        </div>
                        <div class="down">
                            <van-dropdown-menu active-color="#3345cb">
                                <van-dropdown-item v-model="countryValue" :options="countryData" @change="changeCountry" />
                            </van-dropdown-menu>
                        </div>
                    </div>
                    <div class="item">
                        <div class="text">{{$t('message.Currency')}}</div>
                        <!-- <div class="bar flexJA flexFs">
                            <div class="tag flexJA" :class="{active: lan.currencyCode == item.code}" v-for="item in currencyList" @click="switchCurrency(item.code)">{{item.code}} {{item.symbol}}</div>
                        </div> -->
                        <div class="input flexJA">
                            <input type="text" :placeholder="$t('message.pleases')" disabled v-model="currencyName">
                        </div>
                        <div class="down">
                            <van-dropdown-menu active-color="#3345cb">
                                <van-dropdown-item v-model="currencyValue" :options="currencyData" @change="changeCurrency" />
                            </van-dropdown-menu>
                        </div>
                    </div>
                    <div class="item">
                        <div class="text">{{$t('message.Language')}}</div>
                        <!-- <div class="bar flexJA flexFs">
                            <div class="tag flexJA" :class="{active: lan.languageCode == item.code}" v-for="item in langList" @click="switchLanguage(item.code)">{{item.languageName}}</div>
                        </div> -->
                        <div class="input flexJA">
                            <input type="text" :placeholder="$t('message.pleases')" disabled v-model="langName">
                        </div>
                        <div class="down">
                            <van-dropdown-menu active-color="#3345cb">
                                <van-dropdown-item v-model="langValue" :options="langData" @change="changeLang" />
                            </van-dropdown-menu>
                        </div>
                    </div>
                </div>
                <div class="flexJA">
                    <div class="btn flexJA uppercase" @click="bindSave()">{{$t('message.submit')}}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'NavigationBar',
    props: {

    },
    data() {
        return {
            show: false,
            showSet: false,
            activeName: '',
            lan: {
				countries: '',
				countriesCode: '',
				language: '',
				languageCode: '',
				currency: '',
				currencyCode: ''
			},
            countryName: '',
            countryValue: '',
            currencyName: '',
            currencyValue: '',
            langName: '',
            langValue: '',
        }
    },
    computed: {
        ...mapState(['config','userInfo','topNav','token','langList','currencyList','countryList','homeBase']),
        countryData() {
            this.lan = this.config
            this.countryList.forEach((item) => {
                item.value = item.country_symbol
                item.text = item.country_code
                if (this.lan.countriesCode == item.country_symbol) {
                    this.countryValue = item.country_symbol
                    this.countryName = item.text
                }                
            })
            return this.countryList
        },
        currencyData() {
            this.lan = this.config
            let list = Object.values(this.currencyList)
            list.forEach((item) => {
                item.value = item.code
                item.text = item.code + ' ' + item.symbol
                if (this.lan.currencyCode == item.code) {
                    this.currencyValue = item.code
                    this.currencyName = item.text
                }
            })
            return list
        },
        langData() {
            this.lan = this.config
            this.langList.forEach((item) => {
                item.value = item.code
                item.text = item.languageName
                if (this.lan.languageCode == item.code) {
                    this.langValue = item.code
                    this.langName = item.text
                }                
            })
            console.log('this.langList',this.langList)
            return this.langList
        },
    },
    mounted() {
		// this.lan = this.config
        // this.countryData.forEach((item) => {
        //     if (this.lan.countriesCode == item.country_symbol) {
        //         this.countryValue = item.country_symbol
        //         this.countryName = item.text
        //     }
        // })
        // this.currencyData.forEach((item) => {
        //     if (this.lan.currencyCode == item.code) {
        //         this.currencyValue = item.code
        //         this.currencyName = item.text
        //     }
        // })
        // this.langData.forEach((item) => {
        //     if (this.lan.languageCode == item.code) {
        //         this.langValue = item.code
        //         this.langName = item.text
        //     }
        // })
	},
    methods: {
        showPopup() {
            this.show = true
        },
        // 跳转到登录注册
        jumpTo(url) {
            if(this.$route.fullPath == url) return
            this.$router.push(url)
            this.show = false
        },
        // 跳转到商品列表
        bindCommodityList(item) {
            let url = `/goodsList?id=${item.id}`
            this.show = false
            if(url == this.$route.fullPath) return
            this.$store.commit('gotMetaInfo', {
				title: `JU-MART ${item.title}`,
				Keywords: item.meta_keywords || 'JU-MART',
				description: item.meta_description || 'JU-MART'
			})
            this.$router.push(url)
        },
        // 切换国家
        changeCountry(code) {
            // console.log(code)
            this.lan.countriesCode = code
            this.countryData.forEach((item) => {
                if (code == item.country_symbol) this.countryName = item.text
            })
            // console.log('this.lan',this.lan)
            // console.log('this.config',this.config)
        },
        // 切换货币
        changeCurrency(code) {
            // console.log(code)
            this.lan.currencyCode = code
            this.currencyData.forEach((item) => {
                if (code == item.code) this.currencyName = item.text
            })
        },
        // 切换语言
        changeLang(code) {
            // console.log(code)
            this.lan.languageCode = code
            this.langData.forEach((item) => {
                if (code == item.code) this.langName = item.text
            })
        },
        // 保存语言
        async bindSave() {
			localStorage.setItem("lang", this.config.languageCode)
			localStorage.setItem('language', JSON.stringify(this.config))
            this.showSet = false,
			location.href = location.href
            // console.log("this.config.languageCode",this.config.languageCode)
            // console.log("JSON.stringify(this.config)",JSON.stringify(this.config))
		},
    }
}
</script>

<style scoped lang="scss">
    .content {
        position: fixed;
        top: 0;
        left: 0;
        width: 375px;
        z-index: 99;
    }
    // .nav {
    //     height: 44px;
    //     background: #ffffff;
    //     padding: 0 15px;
    //     border-bottom: 2px solid #f4f5f7;
    //     .unfold {
    //         width: 24px;
    //         height: 24px;
    //         margin-right: 15px;
    //     }
    //     .logo {
    //         flex: 1;
    //         img {
    //             width: 68px;
    //             height: 30px;
    //         }
    //     }
    //     .menu {
    //         img {
    //             width: 24px;
    //             height: 24px;
    //             margin-left: 15px;
    //         }
    //     }
    // }

    .top {
        background: #fff;
        border-bottom: 1px solid #F4F5F7;
        .black {
            height: 33px;
            font-size: 13px;
            color: #fff;
            background: #000;
        }
        .menu {
            margin: 0 10px;
            height: 38px;
            .left {
                .unfold {
                    width: 24px;
                    height: 15px;
                    margin-right: 13px;
                }
                .logo {
                    //width: 100px;
                    height: 35px;
                    margin-top: 3px;
                }
            }
            .right {
                .icon {
                    width: 22px;
                    height: 22px;
                    margin-left: 15px;
                }
            }
        }
    }

    .popup {
        // width: 324px;
        width: 310px;
        height: 100%;
        box-sizing: border-box;

        .above {
            padding: 25px 0 10px;
            margin: 0 15px;
            border-bottom: 1px solid #E4E4E4;
            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
            .text {
                flex: 1;
                font-size: 15px;
                color: #333333;
            }
        }
        .info {
            margin-top: 30px;
            padding: 0 15px;
            margin-bottom: 10px;
            .circle {
                width: 40px;
                height: 40px;
                font-size: 20px;
                color: #ffffff;
                background: #3345cb;
                border-radius: 50%;
            }
            .name {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
                margin-left: 15px;
            }
            .line {
                margin: 0 10px;
            }
        }
        .collapse {
            height: calc(100% - 150px);
            overflow-y: auto;
            .title {
                font-size: 15px;
                color: #333333;
                margin-bottom: 10px;
            }
            .list {
                padding-bottom: 10px;
                .text {
                    font-size: 14px;
                    color: #666666;
                    padding: 10px 0;
                }
            }
        }
    }

    .set {
        .top {
            position: fixed;
            top: 0;
            left: 0;
            width: 375px;
            height: 44px;
            border-bottom: 2px solid #F4F5F7;
            background: #ffffff;
            z-index: 99;
            img {
                position: absolute;
                top: 12px;
                left: 15px;
                width: 20px;
                height: 20px;
            }
            .title {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
        }
        .tips {
            font-size: 14px;
            color: #666666;
            padding: 0 15px;
            margin-top: 20px;
        }
        .list {
            padding: 0 15px;
            .item {
                position: relative;
                .text {
                    font-size: 15px;
                    color: #999999;
                    margin: 30px 0 10px;
                }
                .bar {
                    flex-wrap: wrap;
                    .tag {
                        width: 146px;
                        height: 36px;
                        font-size: 14px;
                        color: #333333;
                        border: 1px solid #E4ECF1;
                        border-radius: 4px;
                        margin-bottom: 20px;
                        margin-right: 22px;
                        text-align: center;
                    }
                }
                .input {
                    height: 44px;
                    border: 1px solid #3345cb !important;
                    input {
                        width: 100%;
                        height: 36px;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 0 10px;
                        color: #3345cb;
                        background: #fff;
                    }
                }
                .down {
                    width: 100%;
                    position: absolute;
                    top: 32px;
                    left: 0;
                }
            }
        }
        .btn {
            width: 312px;
            height: 44px;
            background: #3345cb;
            border-radius: 22px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            margin: 80px 0 20px;
        }
        .active {
            color: #3345cb !important;
            border: 1px solid #3345cb !important;
        }
    }

    ::v-deep .van-cell {
        // padding: 15px 0 15px 15px;
        // width: 316px !important;
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
    }
    ::v-deep .van-collapse-item {
        margin: 10px 0;
    }
    ::v-deep .van-collapse-item--border::after {
       border-top: none;
    }
    ::v-deep .van-collapse::after {
       border: none;
    }
    ::v-deep .van-collapse-item__title--expanded {
        // width: 316px !important;
        width: 302px !important;
        background: #D8EAF4 !important;
        border-radius: 0px 25px 25px 0px !important;
        box-sizing: border-box;
    }

    ::v-deep .van-dropdown-menu__bar {
        background: none;
        box-shadow: none;
    }
    ::v-deep .van-dropdown-menu__title::after {
        opacity: 0;
    }
    ::v-deep .van-dropdown-menu__title {
        opacity: 0;
    }
    ::v-deep .van-cell::after {
        border-bottom: none;
    }
    ::v-deep .van-dropdown-item__content {
        padding: 0 15px;
        max-height: 62%;
        box-sizing: border-box;
    }
</style>
