const messagesEn = {
	already_got_an_account: 'Already have an account?',
	signUp: 'Sign Up',
	register: 'Register',
    login: 'Login',
	email: 'Email',
	getCode: 'Get Code',
	securityCode: 'Security code',
	password: 'Password',
	confirmPassword: 'Confirm password',
	please: 'Please enter',
	pleases: 'Please enter the',
	terms: 'Terms and conditions',
	privacy: 'Privacy Policy',
	Already: 'Already have an account?',
	and: 'and',
	agr1: "By creating an account, you agree that your data will be used in accordance with JU-MART's",
	agr2: 'Check this box if you DO NOT WISH to receive marketing materials from us in the future.',
	createAccount: 'Create account',
	please_check_the: 'Please check the',
	LOG_IN: 'LOG IN',
	login_text1: 'Login can speed up the settlement process and save purchase records',
	login_text2: 'Create your own account so that you can view order information and track order status at any time',
	Forgot_password: 'Forgot password ?',
	CREATE_AN_ACCOUNT: 'CREATE AN ACCOUNT',
	forgot_password: 'forgot password?',
	forget_text1: 'To reset your password, please enter your email address',
	reset_password: 'Reset password',
	confirm_the_new_password: 'Confirm the new password',
	proceed_to_Log_In: 'Proceed to Log In',
	log_in: 'log in',
	set_title: 'Change address, language',
	set_text: 'Please select your shipping destination and currency',
	Countries: 'Countries',
	Currency: 'Currency',
	Language: 'Language',
	ShippingTo: 'Shipping To',
	CONFIRM: 'CONFIRM',
	Metal: 'Metal',
	Size: 'Size',
	Additional_Certification: 'Additional Certification',
	Amount: 'Amount',
	addCart: 'Add to Shopping Cart',
	CheckOut: 'Check Out',
	Guide: 'rings size guide',
	serviceA: 'Find the local JU-MART DEMO SHOP where you can schedule a personalized consultation with our jewelry expert.',
	serviceB: 'Find now',
	write_a_review: 'Write a review',
	ViewMore: 'VIEW MORE',
	Recommend: 'RECOMMEND TO YOU',
	Collocation: 'Most Often Styled With',
	YouLike: 'You May also Like',
	Details: 'Details',
	add_success: 'success',
	Inch: 'Inch',
	Sort: 'Sort',
	Filter: 'Filter',
	NoLog: 'log on first please',
	PartnerDesigners: 'Partner Designers',
	CustomDesignStudio: 'Custom Design Studio',
	DesignReady: 'Design Ready',
	love_text: "Put the light of your love in the red line of fate Let's make our story different",
	Browse: 'Browse',
	best: 'OUR BEST SELLER',
	bestsellers: 'BESTSELLERS',
	diamond_btn: 'Start with Diamond',
	CustomCategories: 'CUSTOMIZE BY CATEGORY',
	Start1: 'Start with ring styles',
	Start2: 'Start with diamonds',
	JM1: 'Bless Jewellery OFFLINE STORE',
	JM2: 'THEY ALL CHOSE Bless Jewellery',
	ContactUs: 'CONTACT US',
	OnlineConsultant: 'Live Chat',
	Search: 'Search',
	ShoppingCart: 'Shopping cart',
	cartEmpty: 'Your shopping cart is empty',
	cart_text: 'The current shopping cart has',
	works: 'works',
	pleaseSelect: 'please select',
	Quantity: 'Quantity',
	Total: 'Total',
	OrderInformation: 'Order Information',
	Items: 'items',
	Subtotal: 'Subtotal',
	PromotionCode: 'Promotion Code',
	Promo_Price: 'PROMO PRICE',
	Promo_Code: 'PROMO CODE',
	promocode: 'Enter the promo code to get a discount',
	confirm: 'confirm',
	Confirm: 'Confirm',
	SettleAccounts: 'Please select items before checking out',
    Accountinformation: 'Account information',
	accountOrderA: 'The login account will be used to track the status of your order',
	accountOrderB: 'following information will be used to track the status of your order',
	accountOrderC: 'Orders cannot be shipped to a P.O. Box.',
	accountOrderD: 'Please provide a street address where a signature can be obtained for receipt.',
	Shippinginformation: 'Shipping information',
	StandardLogistics: 'Standard Logistics',
	PickStore: 'Pick up at a Store',
	Theeditor: 'The editor',
	Edit: 'Edit',
	Title: 'Title',
	FirstName: 'First Name',
	LastName: 'Last Name',
	PhoneNumber: 'Phone Number',
	StateProvince: 'State/Province',
	PostalCode: 'Zip / Postal Code',
	AddressLine1: 'Address Line 1',
	AddressLine2: 'Address Line 2',
	PaymentMethod: 'Payment Method',
	Postscript: 'Postscript',
	PostscriptFA: 'If you have any other requirements, please fill in here',
	SpecialRequirement: 'Special Requirement',
	PaymentAgrFA: 'I understand and accept the',
	PaymentAgrFB: 'Terms and Conditions',
	PaymentAgrFC: 'including',
	PaymentAgrFD: 'Payment',
	PaymentAgrFE: 'Return Policy',
	UnitPrice: 'Unit Price',
	ShippingAndInsurance: 'Shipping and Insurance',
	TaxAndDuty: 'Tax and Duty',
	gender0: 'secret',
	gender1: 'Mr.',
	gender2: 'Ms.',
	PaymentType: 'Please select the method of payment',
	MyDetails: 'My Details',
	OrderStatus: 'Order status',
	MyAppointment: 'My Appointment',
	Account: 'Account',
	SignOut: 'Sign out',
	Birthday: 'Birthday',
	RelationshipStatus: 'Relationship status',
	Unmarried: 'Unmarried',
	Married: 'Married',
	Engaged: 'Engaged',
	anniversary: 'What is your anniversary?',
	PleaseDate: 'Please select a date',
	complete: 'complete',
	cancel: 'cancel',
	YourEmail: 'Your email',
	YourPassword: 'Your password',
	YourNewPassword: 'Your new password',
	reconfirm: 'Confirm Again',
	PhoneNumber: 'Phone number',
	NewNumber: 'New number',
	PasswordTips: 'Enter the below information to change your password',
	WishList: 'Wish list',
	have: 'You currently have',
	WishLists: 'wish lists',
	listEmpty: 'Your wish list is empty',
	OrderNo: 'Order No',
	Complete: 'Complete',
	details: 'details',
	loading: 'loading...',
	MyOrder: 'My order',
	MyApplication: 'My application',
	ShippingInformation: 'Shipping Information',
	CommodityInformation: 'Commodity Information',
	Create: 'Create',
	Payment: 'Payment',
	OrderComfirmed: 'Order comfirmed',
	ShippedDate: 'Shipped',
	CompleteDate: 'Complete',
	Refund: 'Refund',
	LogisticsInformation: 'Logistics information',
	ConfirmReceipt: 'Confirm Receipt',
	ProceedToPayment: 'Proceed To Payment',
	PaymentSuccess: 'PAYMENT SUCCESS',
	PaymentFailure: 'PAYMENT FAILURE',
	ContinuePurchase: 'Continue Purchase',
	PaymentFA: 'Our customer service team has received your order and is currently reviewing it.',
	PaymentFB: 'Payment failed, please pay again.',
	AppointmentDetails: 'Appointment details',
	StoreService: 'Store service',
	BusinessHours: 'Business hours',
	Change: 'Change',
	shopFA: 'Find a shop near me',
	shopFB: 'MAKE A APPOINTMENT',
	Location: 'Location',
	CityRegion: 'city region',
	SelectStore: 'Select store',
	VisitingAppointment: 'Visiting Appointment',
	appointmentFA: 'Choose a store of you preference, and enjoy the custom design experience with the support of our friendly staff. Complete the form and submit, we will contact you for confirmation via phone or email.',
	appointmentFB: 'Which store do you want',
	appointmentFC: 'Your desired contact information',
	appointmentFD: 'Please elaborate on how we can help you.',
	appointmentFE: 'Do you already have something in mind? (Optional)',
	appointmentFF: 'We need to collect and process your personal data in response to your request. If you use the Services, you have read and accept the',
	appointmentFG: 'We will contact you',
	appointmentFH: 'We have received your request and will contact you within 48 hours to arrange an in-store appointment.',
	appointmentFI: 'Your selection',
	appointmentFJ: 'Your appointment at “{count}” is approved. The details are as follows:',
	appointmentFK: 'By checking this box, I agree to the',
	appointmentFL: 'and consent to the given information being used by JU-MART.',
    PrivacyTerms: 'Privacy Terms.',
	WishList: 'Wish list',
	ShoppingCart: 'Shopping Cart',
	MyDesign: 'My Design',
	Choose: 'Choose',
	AppointmentTime: 'Appointment date and time',
	HomePage: 'Home page',
	Purpose: 'Purpose',
	// AppointmentTime: 'Appointment Time',
	IChooseThe: 'I Choose The',
	CancelSuccess: 'cancel success',
	Category: 'Category',
	Clear: 'Clear',
	Found: 'Found',
	results: 'Results',
	PleaseKeywords: 'Please enter keywords',
	recommend: 'Recommend',
	SearchFA: 'Design your own order ring In your way',
	goto: 'GO TO',
	prompt: 'prompt',
	promptFA: 'You are not logged in yet. Would you like to login?',
	DeleteRecord: 'delete record?',
	Share: 'Share',
	resultsFA: '"{count}" did not find any results',
	resultsFB: 'Please try another search',
	resultsFC: 'Did not find any results',
	GuideFA: 'Take a non stretchable rope, ribbon, thread or a thin piece of paper. Wrap it comfortably around the ring finger or other fingers, close to the knuckle',
	GuideFB: 'Carefully mark the position where the strings overlap with a pen. For best accuracy, make the smallest possible mark',
	GuideFC: 'The length of the open tag. Use a ruler to measure in millimeters or inches. Enter your measurements below',
	GuideFE: 'Input your size',
	GuideFF: 'Do you want to save the measured size to your account ?',
	GuideFG: 'Yes, I want to save it',
	ScaleUnits: 'Scale Units',
	Start: 'Start',
	MeasureResult: 'Measure result',
	MeasureResultFB: 'Your size is',
	send: 'Unique and tasteful items let you gift your ones, give to your',
	DataEmpty: 'Data is empty',
	RefundFA: 'Request for Return / Refund',
	RefundFB: 'Effective within (7) days of receiving the item, except customized item. Submit your application and our customer representative will get back to you on the follow up actions.',
    RefundFC: 'Service type',
	RefundFD: 'Reasons for return and replacement',
	RefundFE: 'Detailed description',
	RefundFF: 'Picture information',
	RefundFG: 'To help us solve the problem better, please upload the picture',
	RefundFH: 'To apply for a refund',
	RefundFI: 'To apply for time',
	RefundFJ: 'Return Type',
	RefundFK: 'Refund Amount',
	refund: '(Refund)',
	Remark: 'Remark',
	submit: 'submit',
	salesAfterFA: 'In Review',
	salesAfterFB: 'Our customer service team has received your refund request and is currently reviewing it for you',
	salesAfterFC: 'Refund denied',
	salesAfterFD: `sorry, but your request has been denied. If you have any questions, please contact our customer service representative. We hope to see you again soon!`,
	salesAfterFE: 'Refund success',
	salesAfterFF: 'Your refund has been approved and the amount requested has been returned to you via your original payment method. Please check.',
	salesAfterFG: 'Out of Stock',
	salesAfterFH: 'We are very sorry. Each brick and stone is unique, the diamonds you chose are out of stock, we have selected several diamonds with similar prices, 4C diamonds for you to replace, still can not meet your requirements, we will return the amount through your original payment method. Thank you for your understanding and hope to see you soon!',
	salesAfterFI: 'Business Reply：',
	salesAfterFJ: 'Review By',
	salesAfterFK: 'Review passed, waiting for merchant refund',
	salesAfterFL: 'Refund has been cancelled',
	salesAfterFM: 'Returns will not be accepted',
	salesAfterFN: 'It is possible that the product affects the second sale',
    PleaseSize: 'Please select size',
	arm: 'Ring Arm',
	accessories: 'Accessories',
	material: 'Material',
	diamond: 'Diamond',
    styles: 'Styles',
	Engraving: 'Engraving',
	EngravingFA: 'Up to 12 characters,6 Chinese characters',
	EngravingFB: 'This product does not support engraving',
	EngravingFC: 'alphanumeric combination up to 12 characters in length',
	Cost: 'Cost',
	DesignFolder: 'Design Folder',
	buy: 'BUY',
	Setting: 'Setting',
	Diamond: 'Diamond',
	ProductFailure: 'Product failure',
	ProductDetails: 'Product Description',
    Latest: 'Latest',
	Price: 'Price',
	Carat: 'Carat',
	Colour: 'Colour',
	Cut: 'Cut',
	Clarity: 'Clarity',
	Polish: 'Polish',
	Symmetry: 'Symmetry',
	Fluor: 'Fluor',
	replace: 'replace',
	ClearFilters: 'Clear Filters',
	points: 'points',
	Choice: 'Choice',
	NoComments: 'No comments',
	WriteReview: 'Write a Review',
	Score: 'Score',
	PleaseContent: 'Please enter the content',
	PostAnonymously: 'Post Anonymously',
	PickupCode: 'Pickup code',
	NoDiamonds: "We didn't find any diamonds that fit the criteria",
	SubmitSuccess: 'submit success',
	ReviewFA: 'You have comments',
	ALL: 'ALL',
	Edit: 'Edit',
	AddCart: 'Add to Shopping Cart',
	SaveName: 'Save name',
	SaveDesign: 'Save to my design',
	Existing: 'Existing',
	Custom: 'Custom',
	Select: 'Select',
	EssentialInformation: 'Essential Information',
	MyDesign: 'My Design',
	designFA: 'You have currently designed',
	designFB: 'works',
	designFC: "You haven't started your design yet.",
	ViewAll: 'View All',
	CompareDesigns: 'Compare Designs',
	Comparison: 'Comparison',
	WEACCEPT: 'WE ACCEPT',
	or: 'or',
	SeeMore: 'SEE MORE',
	DiscoverMore: 'Discover More',
	HomeFA: 'Enjoy 10% On First Order',
	HomeFB: 'Be the first to experience our 3D customization & Virtual Try On',
	HomeFC: "BEST SELLER",
	HomeFD: 'FREE HONG KONG SHIPPING', 
	pleaseFA: 'Please select a title',
	pleaseFB: 'Please enter the first name',
	pleaseFC: 'Please enter the last name',
	pleaseFD: 'Please enter email',
	pleaseFE: 'Please enter  mobile number',
	pleaseFF: 'Please select an appointment time',
	pleaseFG: 'Please check the Privacy policy',
	Customization: 'Customizatio',
	Shopping: 'RETURN TO SHOPPING',
	RefundOrReturn: 'Refund Or Return',
	uploading: 'uploading',
	done: 'done',
	changeFA: 'Change',
	changeFB: 'Change',
	photoFA: 'Take a photo',
	photoFB: 'Retake the photo',
	flip: 'Flip',
	mirror: 'Mirror',
	share: 'Share',
	back: 'Back',
	TryOn1: 'Try It On',
	TryOn2: "I'll try it on",
	TouchFA: 'Touch and drag to rotate',
	TouchFB: 'Touch and drag to move',
	TouchFC: 'Pinch with two fingers to zoom in or out',
	shareFB: 'Long press share',
	CookiePolicy: 'Cookie Policy',
	TermsOfUse: 'Terms of Use',
	taxes: '**** Taxes are excluded ****',
	subscribeFA: 'Get Exclusive Offers and News',
	subscribeFB: 'You Email',
	subscribeFC: 'SIGN UP',
	subscribeFD: 'Thanks for subscribing!',
	Cancel: 'Cancel',
	Search: 'Search',
	EditSuccess: 'Edit Success',
	Hi: 'Hi',
	ChooseFa: 'Choose this diamond',
	ChooseFb: 'Please select diamond',
	Certification: 'Certification',
	ReportNumber: 'Report number',
	Shape: 'Shape',
	Depth: 'Depth %',
	Table: 'Table %',
	Measurements: 'Measurements',
	StockNumber: 'Stock Number',
	Location: 'Location',
	StartWithDiamonds: 'Start with diamonds',
	SETTING: 'SETTING',
	DiamondGemstone: 'Diamond/ Gemstone',
	RecentlyViewed: 'Recently Viewed',
	NotFun: 'Product is not exist or off the shelf',
	low: 'low',
	high: 'high',
	

    language: 'Language',
    currency: 'Currency',
    sort: 'sort order',
    filter: 'Filter',
    price: 'Price',
    reviews: 'reviews',
    description: 'Description',
    cart: 'Cart',
    subtotal: 'Subtotal',
    first_name: 'First Name',
    last_name: 'Last Name',
    telephone: 'Telephone',
    street: 'Street',
    country: 'Country',
    state: 'State',
    city: 'City',
    password: 'Password',
    account: 'Account',
    home: 'home page',
    sign_in: 'Sign In',
    logout: 'Logout',
    name: 'Name',
    address: 'Address',
    date: 'Date',
    submit: 'Submit',
    review: 'Review',
    rate: 'Rate',
    name: 'Name',
	accessories: 'Accessories',
	gem: 'gem',
	price: 'price',
	Appointment: 'My Appointment',
	ShoppingCart: 'Shopping cart',
	Account: 'Account',

	Waiting: 'Waiting for payment',
	WaitingFA: 'Your order has been created, please continue to complete the payment; If the payment is not made within 60 minutes, the order will be cancelled automatically.',
	WaitingFB: 'Payment processing in progress',
	WaitingFC: 'Payment success',
	WaitingFD: 'Fraud',
	InReview: 'In Review',
	InReviewFA: 'Our customer service team has received your order and is currently reviewing it for you.',
	wSend: 'The order has been confirmed',
	wSendFA: 'Customer service has confirmed your order. We will arrange the order processing and delivery to you as soon as possible.',
	wGoods: 'Your order has been shipped',
	wGoodsFA: 'Please check the tracking number of the shipper to check the logistics status.',
	wreceived: 'Your order has been received',
	wEnd: 'Your order is complete',
	wEndFA: 'Sign and receive orders. Thank you for shopping at Ju-Mart and hope you like your new jewelry! Please give us your comments and suggestions. We hope to see you again soon!',
	wExpired: 'Your order has been cancelled',
	wExpiredFA: 'Your order has been cancelled',
	wExpiredFB: 'We are sorry to see you go! Your payment is overdue and your order is cancelled. We look forward to seeing you again soon!',
	wExpiredFC: 'Your payment is overdue',
	Complete: 'Complete',
}
export default messagesEn